<template lang="pug">
  v-app
    v-card(
      class="mom-card"
      style="border-radius: 20px"
    )
      v-card-title
        h3(style="color: black")
          b {{ $t("auth.forgotpassword") }}
        v-spacer
        v-icon(
          @click="$router.go(-1)"
          color="primary"
          v-bind="size"
        ) mdi-close-box
      v-card-text
        v-form(
          v-model="valid"
          ref="request"
          lazy-validation
        )
          label(for="email") {{ $t("auth.email") }}
          v-text-field(
            name="email"
            outlined
            v-model.trim="customerEmail" 
            :rules="emailRules"
            required
          )
      v-card-actions
        v-btn(class="m-auto" color="primary" @click="request") 
          b {{ $t("auth.request") }}
          i.fas.fa-spin.fa-spinner.ml-2(v-if="loading")
</template>

<script>
import config from "@/config";
import errorTags from "@/errorTags";

export default {
  name: "request",
  computed: {
    size() {
      const size = { xs: "large", sm: "large", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    mobileView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    }
  },
  data() {
    return {
      valid: false,
      customerEmail: "",
      password: "",
      emailRules: [
        v => !!v || this.$t("auth.email_required"),
        v => /.+@.+\..+/.test(v) || this.$t("auth.email_format")
      ],
      passwordRules: [
        v => !!v || this.$t("auth.password_required"),
        v => (v && v.length >= 8) || this.$t("auth.password_format")
      ],
      loading: false,
      feedback: {
        success: false,
        status: errorTags.request.failed
      }
    };
  },
  methods: {
    request() {
      if (!this.$refs.request.validate()) return;

      let url = config.forgotPwUrl();

      var json = {
        email: this.customerEmail
      };

      this.loading = true;

      this.$http
        .post(url, json)
        .then(function(response) {
          this.loading = false;
          // Success
          try {
            if (response) {
              

              this.feedback.success =
                response.body.data ||
                this.$helpers.handleResponse(response.body.status)
                  ? true
                  : false;

              if (this.feedback.success) {
                // console.log(response.body.data);
                this.$swal(
                  this.$t("success.request"),
                  this.$t("success.request"),
                  "success"
                );
                this.$router.push("/");
              } else {
                this.$swal(
                  this.$t("error.request"),
                  response.body.error.message,
                  "error"
                );
              }
            }
          } catch (error) {
            this.feedback.success = false;
            this.$swal(
              this.$t("error.request"),
              response.body.error.message,
              "error"
            );
          }
        })
        .catch(response => {
          this.feedback.success = false;
          this.$swal(
            this.$t("error.request"),
            response.body.error.message,
            "error"
          );
        });
    },
    reset() {
      this.$refs.request.reset();
    }
  }
};
</script>

<style lang="sass" scoped>
.mom-card
  width: 1000px
  margin: auto
  margin-top: 8vw
  padding: 1rem
  margin-bottom: 4rem

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    width: unset
    max-width: 600px
    margin-top: 12vw
    margin-left: 1rem
    margin-right: 1rem
</style>
